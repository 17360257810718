import React from 'react';
import { Link } from 'gatsby';
import '../../../styles/scss/style.scss';

export const Feature = () => {
  return (
    <div  className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">
          <div className="feature__left">
            <img
              src={require('../../../components/images/aboutfeatfree.png')}
              alt="#"
              className="feature__img"
            />
          </div>
          <div className="feature__right">
            <h2 className="feature__lead feature__lead--1 showcase__lead">
              Seamlessly created reusable UI components to make your next project as beautiful.
            </h2>
            <p className="feature__text">
              Contrast Design Bootstrap is an elegant bootstrap UI kit featuring over 2000+ basic components,
              which can be integrated with any react project to build mobile first, responsive and 
              elegant websites and web apps.
            </p>
            <ul className="feature-2__list">
              <li>
                Over 2000 components included, some of which are buttons,
                forms, alerts, datepickers, cards and many more.
              </li>
            </ul>
            <Link to="/docs/contrast/react/index/" className="btn1 btn__inverse">
              View Documentation
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
