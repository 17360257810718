import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'gatsby';


export const VideoSection = () => {
  return (
    <div className="text-light py-5" style={{ minHeight: '100vh', background: 'rgba(26,32,44,1)' }}>
      <div className="container">
        <div className="feature-3 mt-5">
          <div className="feature__wrap">
            <div className="mx-auto text-center">
              <h1 className="font-bold text-4xl mb-3 mx-auto" style={{ maxWidth: '800px' }}>
                Create elegant components, pages and dashboards for your projects in minutes
              </h1>
              <p className="mb-4 mx-auto" style={{ maxWidth: '600px' }}>
                {' '}
                With contrast you can build beautiful, well crafted components and pages for your
                projects using contrast. Check out our free admin template built with contrast
                <Link to="/product/admin-contrast"> here.</Link>
              </p>
              <div className="mb-5 mx-auto">
                <a target="_blank" rel="noreferrer" href="https://cdbreact-demo-free.netlify.app/">
                  <button className="btn btn-success mx-auto mr-2" style={{ display: 'inline' }}>
                    Preview Components{' '}
                  </button>
                </a>
                <Link to="/docs/contrast/react/Installation">
                  <button className="btn btn-outline-success mx-auto" style={{ display: 'inline' }}>
                    Get Contrast
                  </button>
                </Link>
              </div>
              {/* <p className="mb-3 mx-auto"><small>There’s no way to describe it. You just have to see it for yourself.</small></p> */}
              <div>
                <video className="mx-auto w-100" style={{ maxWidth: '920px' }} controls>
                  <source src={require('../../../components/images/demo1.mp4')} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
