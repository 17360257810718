import React from 'react';
import { Link } from 'gatsby';


export const Hero4 = () => {

  return (
   <div className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">  
          <div className="feature__left">
            <h2 className="feature__lead feature__lead--1 showcase__lead">
              Get Started using Contrast in 2 minutes
            </h2>
            <p className="feature__text">
              We packaged the Contrast design Bootstrap as an npm package that can be installed via <b>npm</b> or <b>yarn</b>. 
              Which means you can get started using it by just running a single command <b>npm install cdbreact.</b>
            </p>
            <p className="feature__text">
              We have also designed a Pro version of Contrast to give you access to more features and improvements. Check it out
            </p>
            <div className="feature__buttons">
              <Link to="/docs/contrast/react/Installation/" className="btn1 btn__inverse">
                <span className="btn__text">Get Contrast</span>
              </Link>
              <Link to="/product/contrast-pro/" className="btn1 btn-white">
                <span className="btn__text" style={{color:"#3329e0"}}>Get Contrast PRO</span>
              </Link>
            </div>
          </div>
          <div className="feature__right">
            <img
              src={require('../../../components/images/aboutlastfree.png')}
              alt="#"
              className="feature__img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
