import React from 'react';
import Helmet from 'react-helmet';

import AppContext, { UserContext } from "../../components/AppContext";
import NavBar from "../../components/NavBar/NavBar";
import { Footer } from "../../components/Footer/Footer";
import { VideoSection } from '../../components/About/React/VideoSection';
import { Hero } from '../../components/About/React/Hero';
import { Hero3 } from '../../components/About/React/Hero3';
import { Hero2 } from '../../components/About/React/Hero2';
import { Hero4 } from '../../components/About/React/Hero4';
import { Feature } from '../../components/About/React/Feature';
import { Showcase } from '../../components/About/React/Showcase';
// import { PagesShowcase } from '../../components/About/React/PagesShowcase';
import { DocsPreview } from '../../components/About/React/DocsPreview';
import { Comparison } from '../../components/About/React/Comparison';
import { FeaturedIn } from '../../components/FeaturedIn/FeaturedIn';
import '../../styles/scss/style.scss'

const Contrast = () => {

  return (
    <AppContext>
      <Helmet>
        <title>React Bootstrap UI Kit Library - Contrast</title>
        <meta name="title" content="React Bootstrap UI Kit Library - Contrast" />
        <meta
          name="description"
          content="React bootstrap well crafted reusable UI components for building modern and responsive websites and webapps"
        />
        <meta
          property="og:title"
          content="React Bootstrap UI Kit Library - Contrast"
        />
        <meta
          property="og:description"
          content="React bootstrap well crafted reusable UI components for building modern and responsive websites and webapps"
        />
        <meta
          property="twitter:title"
          content="React Bootstrap UI Kit Library - Contrast"
        />
        <meta
          property="twitter:description"
          content="React bootstrap well crafted reusable UI components for building modern and responsive websites and webapps"
        />
        <link rel="canonical" href="https://www.devwares.com/product/contrast/" />
        <meta property="og:url" content="https://www.devwares.com/product/contrast/" />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643029480/react-bootstrap-contrast_uowqep.jpg" />
        <meta name="twitter:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643029480/react-bootstrap-contrast_uowqep.jpg" />

      </Helmet>
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="container">
              <NavBar user={user} />
            </div>
            <Hero />
            <VideoSection />
            <div className="mt-5">
              <FeaturedIn />
            </div>
            <Feature />
            <Hero3 />
            <Hero2 />
            <Hero4 />
            <DocsPreview />
            <Showcase />
            <Comparison />
            <div className="container">
              <Footer />
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default Contrast;
