import React, { Fragment } from 'react';
import GitHubButton from 'react-github-btn';


export const Comparison = () => {
  return (
    <Fragment>
      <div  className="container" id="pricing">
        <div className="comparison">
          <div className="comparison__title">
            <h2 className="comparison__lead">Install and Use Contrast</h2>
            <p className="comparison__sub-title text-center">
              You can support Contrast by leaving a star on our github to help others discover it and spread the word. 
              We will greatly appreciate it.
              <div className="mt-2 d-flex align-items-center justify-content-center">
                <a target="_blank" rel="noreferrer" href="https://github.com/Devwares-Team/cdbreact"><button className="btn btn-secondary btn-sm rounded mb-2">View on Github</button></a>
                <GitHubButton
                  href={"https://github.com/" + "Devwares-Team/cdbreact"}
                  data-icon="octicon-star" data-size="large"
                  data-show-count="true"
                  aria-label={"Star " + "Contrast React Bootstrap" + " on GitHub"}
                >
                  Star
                </GitHubButton>
              </div>
            </p>
          </div>
          <div className="comparison__wrap">
            <div className="comparison__box">
              <div className="comparison__price">Contrast</div>
              <ul className="comparison__list2">
                <li className="good">Full documentation</li>
                <li className="good">2000+ Components</li>
                <li className="bad">27+ example Pages</li> 
                <li className="bad">Custom PRO Components</li>
                <li className="bad">Multilevel Sidebar</li>
                <li className="bad">Advanced Dashboards</li>
                <li className="bad">About Pages</li>
                <li className="bad">Chat Page</li>
              </ul>
              <a href="/docs/contrast/react/index/" className="btn1 btn__inverse btn__full">
                Install this
              </a>
            </div>
            <div className="comparison__box">
              <div className="comparison__price">Contrast + Free Admin Template</div>
              <ul className="comparison__list2">
                <li className="good">Full documentation</li>
                <li className="good">2000+ Components</li>
                <li className="good">8+ example Pages</li>
                <li className="bad">Custom PRO Components</li>
                <li className="bad">Multilevel Sidebar</li>
                <li className="bad">Advanced Dashboards</li>
                <li className="bad">About Pages</li>
                <li className="bad">Chat Page</li>
              </ul>
              <a href="/product/admin-contrast/" className="btn1 btn__inverse btn__full">
                Get this
              </a>
            </div>
            <div className="comparison__box">
              <div className="comparison__price">Contrast PRO + Pro Admin Template</div>        
              <ul className="comparison__list">
                <li>Full documentation</li>
                <li>10000+ components</li>
                <li>27+ example pages</li>
                <li>Custom PRO Components</li>
                <li>Multilevel Sidebar</li>
                <li>Advanced Dashboards</li>
                <li>About Pages</li>
                <li>Chat Page</li>
                <li>VIP Early access</li>
              </ul>
              <a href="/product/contrast-pro/" className="btn1 btn__inverse btn__full">
                Get Pro
              </a>
            </div>
          </div>
        </div>
       
      </div>
    </Fragment>
  );
};
